.homepage {
  text-align: center;
  background-color: black;
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 95px;
}

.header {
  background-color: black;
  padding: 20px;
  padding-bottom: 3vh;
}

.main {
  padding: 10px;
  /* background-color: #C3AC7E; */
  border-radius: 15px;
  color: #c3ac7e;
  width: 85vw;
  border: solid 1px #c3ac7e;
}

.innerBox {
  width: 98%;
  border: solid 1px white;
  border-radius: 15px;
  margin: auto;
  background-color: black;
}

.boxHeading {
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 0;
}
.boxSubHeading {
  color: white;
  font-weight: 400;
  font-size: 0.8rem;
  margin: 0;
}

.buttons {
  margin: 13px;
}

.sign-in,
.book-now,
.call-now {
  /* background-color: #C3AC7E; */
  background-image: linear-gradient(144deg, #c3ac7e, white 50%, #c3ac7e);
  color: black;
  border: solid 1px white;
  padding: 10px 30px;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 15px;
}

.social-media {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.social-media img {
  width: 15px;
  height: 15px;
  margin: 10px;
}

.call-now {
  margin-top: 5vh;
  background-image: linear-gradient(144deg,#C3AC7E, white 50%,#C3AC7E);
  color: black;
  border: solid 3px white;
   padding: 0.4em 5em;
  font-size: 1.2rem;
  outline: none;
}

